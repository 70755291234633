<template>
  <Navigation/>
</template>
<script>
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Home',
  components:{
    Navigation
  },
  created(){
    var encoded = localStorage.getItem("log");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_USERLOGON", actual);
  },
  methods:{
  },
  computed:{
     LOGON_USER() {
        return this.$store.state.Logon;
      },
  }
}
</script>